"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var product_mapper_1 = require("@wix/wixstores-platform-common/dist/src/products/product.mapper");
/**
 * @class ProductPage
 * @summary A store page for a specific product.
 * @mixes $w.Element
 * @mixes $w.HiddenCollapsedMixin
 * @memberof $w
 * @viewername wysiwyg.viewer.components.tpapps.TPAWidget
 * @tagname productPage
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProductRibbon
 * @summary An object representing a ribbon for a store product.
 * @property {external:String} text Ribbon text.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProduct](#StoreProduct)
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProductAdditionalInfoSection
 * @summary An object representing an additional info section for a store product.
 * @property {external:String} title Section title.
 * @property {external:String} description Section description.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProduct](#StoreProduct)
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProductOptionsChoice
 * @summary An object representing an options choice for a store product.
 * @property {external:Number} value Choice value.
 * @property {external:Number} description Choice description.
 * @property {$w.ProductPage~StoreProductOptionsChoiceMedia} media Choice media.
 * @property {external:Boolean} inStock Indicates whether the product with this choice is in stock.
 * @property {external:Boolean} visible Indicates whether the product with this option is visible.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProductOption](#StoreProductOption)
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProductOptionsChoiceMedia
 * @summary An object representing the choice media
 * @property {external:String} mainMedia Main choice media item (image or video thumbnail) URL.
 * @property {($w.Gallery~ImageItem[]|$w.Gallery~VideoItem[])} mediaItems List of choice media items.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProductOptionsChoice](#StoreProductOptionsChoice)
 */
/**
 * @typedef $w.ProductPage~StoreProductOptions
 * @summary An object representing all the available options for a store product.
 * @property {$w.ProductPage~StoreProductOption} optionKey Name of the option. This key name is
 *  dependent on the options added to the product. For example, if a product
 *  has a size option, this key will be something like "Size".
 * @see [getProduct( )](#getProduct)
 * @see [StoreProduct](#StoreProduct)
 */
/**
 * @typedef $w.ProductPage~StoreProductOption
 * @summary An object representing an option for a store product.
 * @property {external:String} optionType Option type. Either `"color"` or `"drop_down"`.
 * @property {external:String} name Option name.
 * @property {$w.ProductPage~StoreProductOptionsChoice[]} choices Option choices.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProductOptions](#StoreProductOptions)
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProductCustomTextFields
 * @summary An object representing a custom text field for a store product.
 * @property {external:String} title Product customization field title.
 * @property {external:String} maxLength Maximum length of product customization field in characters.
 * @see [getProduct( )](#getProduct)
 * @see [StoreProduct](#StoreProduct)
 */
/**
 * @typedef {external:Object} $w.ProductPage~StoreProduct
 * @summary An object representing a product in a store.
 * @property {external:String} _id Product ID.
 * @property {external:String} name Product name.
 * @property {external:String} description Product description.
 * @property {external:String} mainMedia Main product media item (image or video thumbnail) URL.
 * @property {($w.Gallery~ImageItem[]|$w.Gallery~VideoItem[])} mediaItems List of product media items.
 * @property {external:String} sku Product stock keeping unit value.
 * @property {$w.ProductPage~StoreProductRibbon[]} ribbons List of product ribbons.
 * @property {external:String} currency Product currency.
 * @property {external:Number} price Product price.
 * @property {external:Number} discountedPrice Discounted product price.
 * @property {external:String} formattedPrice Product price formatted with the currency.
 * @property {external:String} formattedDiscountedPrice Discounted product price formatted with the currency.
 * @property {external:Boolean} trackInventory Indicates whether inventory is tracked for the product.
 * @property {external:Boolean} inStock Indicates whether the product is in stock.
 * @property {external:Number} quantityInStock Number of units currently in stock.
 * @property {$w.ProductPage~StoreProductAdditionalInfoSection[]} additionalInfoSections Additional product information sections.
 * @property {$w.ProductPage~StoreProductOptions} productOptions All the available options for a store product.
 * @property {external:String} productPageUrl Product page relative URL.
 * @property {external:Boolean} manageVariants Indicates whether product variants are managed.
 * @property {$w.ProductPage~StoreProductCustomTextFields[]} customTextField List of product customization fields.
 * @property {external:String} productType Product type. Either `"physical"` or `"digital"`.
 * @property {external:String} slug Product slug.
 * @property {external:String} weight Product weight.
 * @snippet [ProductPage-getProduct.es6=Get the current product's information]
 * @see [getProduct( )](#getProduct)
 */
exports.productPageController = function (context) {
    var fetchProduct = function () {
        var encodedProductName = _.last(wix.location.path);
        var productName = decodeURIComponent(encodedProductName);
        return fetch("/_api/catalog-server/api/v1/products/query", {
            method: 'post',
            headers: { Authorization: context._instance },
            body: JSON.stringify({
                query: {
                    filter: JSON.stringify({ slug: productName })
                }
            })
        })
            .then(function (response) { return response.json(); })
            .then(function (json) { return json.products && json.products[0]; });
    };
    return {
        pageReady: function (_$w) {
            //
        },
        exports: {
            /**
             * @function getProduct
             * @syntax
             * function getProduct(): Promise<StoreProduct>
             * @summary Gets the all the information associated with the currect product.
             * @description
             *  The `getProduct()` function returns a Promise that is resolved when all
             *  of the information about the product shown on the `ProductPage` is
             *  retrieved.
             * @returns {Promise}
             * @fulfill {$w.ProductPage~StoreProduct} When the information associated with the currect product has been retrieved.
             * @snippet [ProductPage-getProduct.es6=Get the current product's information]
             * @memberof $w.ProductPage
             */
            getProduct: function () {
                return fetchProduct()
                    .then(function (product) {
                    if (product) {
                        return product_mapper_1.productMapper(product);
                    }
                    else {
                        throw new Error('product not found');
                    }
                })
                    .catch(context.reportError);
            }
        }
    };
};
